/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageColombia/pageWhatsappPrecio/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageColombia/pageWhatsappPrecio/intro/intro"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import Conversions from "@components/pageColombia/pageWhatsappPrecio/conversions/conversions"
import Calculador from "@components/pageColombia/pageWhatsappPrecio/calculador/calculador"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import { TitleUnderlineLeftOne, TitleUnderlineLeftTwo } from "@components/pageChannels/pageChatbots/titleUnderline/titleUnderlineAccordionLeft"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"

const TemplatePageWhatsappPrecio = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionLeft = <p>
    Beex <TitleUnderlineLeftOne underline="potencia las" /> <TitleUnderlineLeftTwo underline="capacidades" /> de uso de la API de WhatsApp Business

  </p>

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/hnr7sfYe210?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}
        <div className="container">
          <Start data={data.start} location={location} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="¡Empresas de LATAM que mejoraron la comunicación con sus clientes con la API de WhatsApp!" />
          <Calculador />
          <AccordionLeft data={data.accordionLeft} title={titleAccordionLeft} description={""} />
          <BannerCTA
            title="Automatiza tu comunicación por WhatsApp ahora mismo"
            text="Agenda una reunión con nuestros expertos y conoce de primera mano cómo aprovechar la API de WhatsApp."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Conversions data={data.conversions} location={location} />
          <BannerCTA
            title="¿Quieres saber más sobre la API de WhatsApp Business?"
            text="Nuestro equipo comercial está dispuesto a ayudarte con tu caso de uso en específico."
            link="/hablemos/"
            textButton="Contacta con nosotros"
          />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageWhatsappPrecio
